<template>
  <div class="hello">

  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'HelloWorld',
  data() {
    return {

    }
  },

  mounted() {
    this.getWxUrl()
  },
  methods: {
    getWxUrl() {
      axios.get('/events/index')
          .then(response => {
            let res = response.data
            // 处理响应数据
            window.location.href = res.data.openlink;
            // console.log(res.data.openlink);
          })
          .catch(error => {
            // 处理错误情况
            console.error(error);
          });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
